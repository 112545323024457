export const colorStyles = {
  singleValue: (base: {}): {
    [key: string]: string
  } => ({
    ...base,
    color: '#ffffffb4',
  }),
  input: (base: {}): {
    [key: string]: string
  } => ({
    ...base,
    color: '#ffffffb4',
  }),
}

export const ARTICLE_PER_PAGE = 9
