import React, { ReactElement, useState, useCallback, useEffect } from 'react'
import { graphql } from 'gatsby'
import './NewsAndEventsList.scss'
import loadable from '@loadable/component'
import Layout from '../../layouts/Layout/Layout'

import LandingPageLayout from '../../layouts/LandingPageLayout/LandingPageLayout'
import searchOptions from '../OurProperties/OurProperties.json'
import Seo from '../../components/Seo/Seo'

import { colorStyles, ARTICLE_PER_PAGE } from './NewsAndEventsList.utils'
import { MainPropTypes, NewsAndEventsTypes } from './NewsAndEventsList.types'

const SearchInput = loadable(
  () => import('../../components/SearchInput/SearchInput'),
)
const SearchSelect = loadable(
  () => import('../../components/SearchSelect/SearchSelect'),
)

const FeaturedCardSlider = loadable(
  () => import('../../components/FeaturedCardSlider/FeaturedCardSlider'),
)

const Pagination = loadable(
  () => import('../../components/Pagination/Pagination'),
)

const NoResult = loadable(() => import('../../components/NoResult/NoResult'))

const ArticleCard = loadable(
  () => import('../../components/ArticleCard/ArticleCard'),
)

const NewsAndEvents = ({
  data: {
    featuredNewsAndEvents: { nodes: featuredNewsAndEvents },
    allNewsAndEvents: { nodes: allNewsAndEvents },
  },
  pageContext: { filterType },
  location: { state: locationState },
}: MainPropTypes): ReactElement => {
  const { searchType: defaultSearchType } = locationState || {}

  const [dataList, setDataList] =
    useState<NewsAndEventsTypes[]>(allNewsAndEvents)
  const [pageDataList, setPageDataList] = useState<NewsAndEventsTypes[]>(
    dataList.slice(0, ARTICLE_PER_PAGE),
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState(
    defaultSearchType?.value || 'All' || null,
  )
  const [locationStateSearch, setLocationStateSearch] = useState<any>(
    locationState?.searchType || '',
  )
  const [featuredImage, setFeaturedImage] = useState<string>(
    featuredNewsAndEvents.length ? featuredNewsAndEvents[0].featuredImage : '',
  )

  const [pages, setPages] = useState(
    Math.ceil(dataList.length / ARTICLE_PER_PAGE),
  )
  const defaultSearchTypeValue = searchOptions.category.find((category) => {
    return category.value === locationState?.searchType?.value
  }) || { value: 'All', label: 'All' }

  const [currentPage, setCurrentPage] = useState(1)
  const [currentSets, setCurrentSets] = useState(1)

  const handleAddSets = useCallback((): void => {
    const newSetCount = currentSets + 1
    setPageDataList(dataList.slice(0, newSetCount * ARTICLE_PER_PAGE))
    setCurrentSets(newSetCount)
  }, [currentSets, dataList])

  useEffect(() => {
    setPages(Math.ceil(dataList.length / ARTICLE_PER_PAGE))
  }, [dataList])

  useEffect(() => {
    setPageDataList(
      dataList
        .filter((news) => !news.tags.includes('banner'))
        .slice(
          (currentPage - 1) * ARTICLE_PER_PAGE,
          currentPage * ARTICLE_PER_PAGE,
        ),
    )
  }, [currentPage, dataList])

  const handlePageClick = useCallback((page: number): void => {
    window.scrollTo({
      top: 300,
    })
    setCurrentPage(page)
  }, [])

  useEffect(() => {
    setCurrentPage(1)
    setCurrentSets(1)

    if (locationState?.searchType) {
      setLocationStateSearch(locationState?.searchType)
    }

    let filteredData

    if (
      locationState?.searchType?.value &&
      locationState?.searchType?.value?.toLowerCase().trim() !==
        locationStateSearch?.value?.toLowerCase().trim()
    ) {
      filteredData = allNewsAndEvents.filter((item) => {
        return (
          item.category ===
          locationState?.searchType?.value.toLocaleLowerCase().trim()
        )
      })
    } else {
      filteredData = allNewsAndEvents.filter((data) => {
        if (searchType !== 'All' && data.category !== searchType) {
          return false
        }

        if (
          searchTerm !== '' &&
          !data.title.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return false
        }

        return true
      })
    }

    setDataList(filteredData)

    if (!searchType) {
      setDataList(
        allNewsAndEvents.filter((data) => {
          return data.category === defaultSearchType?.value
        }),
      )
    }

    if (searchType === null) {
      setSearchType('All')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNewsAndEvents, searchTerm, searchType, defaultSearchType])

  const handleSearchTypeChange = useCallback((value): void => {
    setSearchType(value?.value)
  }, [])

  const handleFeaturedChange = useCallback((image: string) => {
    setFeaturedImage(image)
  }, [])

  return (
    <Layout navButtonVariant="burger">
      <Seo
        title={`News & Events${filterType ? ` | ${filterType}` : ''}`}
        ogMetaData={{
          title: `News & Events${filterType ? ` | ${filterType}` : ''}`,
          description:
            'Keeping you in the loop on Avida’s latest news, events, and other initiatives',
          image: featuredImage,
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/news-and-events/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, news, events, awards, news and events',
        }}
      />
      <LandingPageLayout
        title="News & Events"
        isSearch
        body="Keeping you in the loop on Avida’s latest news, events, and other initiatives"
        background={featuredImage}
      >
        <SearchInput
          name="city"
          placeholder="Find exactly what you are looking for"
          setSearchTerm={setSearchTerm}
          className="search-input"
        />
        <SearchSelect
          options={searchOptions.category}
          placeholder="Category"
          className="search-select"
          onChange={handleSearchTypeChange}
          isStyles={colorStyles}
          defaultValue={defaultSearchTypeValue}
        />
      </LandingPageLayout>
      <div className="news-events-list">
        <div className="news-events-list-content">
          {!!featuredNewsAndEvents.length && (
            <FeaturedCardSlider
              data={featuredNewsAndEvents}
              onChange={handleFeaturedChange}
            />
          )}
          <div className="news-events-list-content-cards">
            {pageDataList.length ? (
              pageDataList.map((article) => (
                <ArticleCard
                  key={article.id}
                  data={article}
                  url={`/news-and-events/${article.slug}/`}
                />
              ))
            ) : (
              <NoResult searchTerm={searchTerm} />
            )}
          </div>
        </div>
        <div className="news-events-list-pagination">
          <Pagination
            pages={pages}
            currentPage={currentPage}
            onPageClick={handlePageClick}
          />
        </div>
        {currentSets < pages && (
          <div className="news-events-list-load-more">
            <button
              type="button"
              className="news-events-list-load-more-button"
              onClick={handleAddSets}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </Layout>
  )
}
export default NewsAndEvents

export const pageQuery = graphql`
  query {
    featuredNewsAndEvents: allNewsAndEvents(
      filter: { featured: { eq: true } }
    ) {
      nodes {
        ...NewsAndEventsListPageArticleFields
      }
    }
    allNewsAndEvents {
      nodes {
        ...NewsAndEventsListPageArticleFields
      }
    }
  }
`
